<template>
  <div class="mx-8">
    <v-card outlined color="primary">
      <v-card-text>
        <div class="d-flex justify-center text-h5 white--text">
          <strong>{{ produk }}</strong>
        </div>
      </v-card-text>
    </v-card>

    <div class="mb-4"></div>

    <div v-if="dataItemPrioritas.hasil == 'success'">
      <v-row>
        <v-col cols="12" md="4">
          <v-alert color="success" dark>
            <div class="d-flex justify-space-between">
              <span><strong>HARGA</strong></span>
              <v-icon @click="showDialogEdit()">mdi-pencil</v-icon>
            </div>
          </v-alert>
          <div class="d-flex justify-space-between mx-4">
            <span>Rentang Harga Bawah</span>
            <span>{{ dataItemPrioritas.data.harga.bawah | formatNumber }}</span>
          </div>
          <v-divider class="mx-4 my-2"></v-divider>
          <div class="d-flex justify-space-between mx-4">
            <span>Rentang Harga Atas</span>
            <span>{{ dataItemPrioritas.data.harga.atas | formatNumber }}</span>
          </div>
          <v-divider class="mx-4 my-2"></v-divider>
          <div class="mx-4">
            <span>Keterangan:</span>
          </div>
          <div class="mx-4 mb-2">
            <span>{{ dataItemPrioritas.data.keterangan }}</span>
          </div>
          <v-divider class="mx-4 my-2"></v-divider>
        </v-col>

        <v-col cols="12" md="8">
          <v-alert color="success" dark>
            <div class="d-flex justify-space-between">
              <span><strong>LINK ITEM</strong></span>
              <v-icon @click="dialogAddLink = true">mdi-plus-thick</v-icon>
            </div>
          </v-alert>
          <v-data-table
            :items="dataItemPrioritas.data.link"
            :headers="headers"
            hide-default-header
            hide-default-footer
            disable-sort
            disable-pagination
            dense
          >
            <template v-slot:item.nomor="{ item }">
              <span>{{ dataItemPrioritas.data.link.indexOf(item) + 1 }}</span>
            </template>

            <template v-slot:item.url="{ item }">
              <span style="word-break: break-all"
                ><a :href="item.url" target="_blank">{{ item.url }}</a>
              </span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    class="mr-2"
                    @click="showDialogEditLink(item)"
                    >mdi-pencil-box</v-icon
                  >
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    @click="showDialogDeleteLink(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Hapus</span>
              </v-tooltip>
            </template>

            <template v-slot:no-data>
              <label>Tidak ada data</label>
            </template>
          </v-data-table>
          <v-divider class="my-2"></v-divider>
        </v-col>
      </v-row>

      <div class="mb-4"></div>

      <v-alert color="success" dark>
        <div class="d-flex justify-space-between">
          <span><strong>GAMBAR ITEM</strong></span>
          <v-icon @click="dialogAddImage = true">mdi-plus-thick</v-icon>
        </div>
      </v-alert>
      <v-row v-if="dataItemPrioritas.data.gambar.length > 0">
        <v-col
          v-for="(gambar, index) in dataItemPrioritas.data.gambar"
          :key="index"
          class="d-flex child-flex"
          cols="12"
          md="2"
          sm="3"
        >
          <v-card>
            <v-img :src="gambar.link" aspect-ratio="1"></v-img>
            <v-card-actions class="justify-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    class="mr-2"
                    @click="showDialogImage(gambar)"
                    >mdi-eye</v-icon
                  >
                </template>
                <span>Lihat</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    class="mr-2"
                    @click="showDialogEditImage(gambar)"
                    >mdi-pencil-box</v-icon
                  >
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    @click="showDialogDeleteImage(gambar)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Hapus</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div v-else>
        <v-card-subtitle class="d-flex justify-center body-2"
          >Tidak ada data</v-card-subtitle
        >
        <v-divider></v-divider>
      </div>

      <div class="mb-8"></div>

      <div class="d-flex justify-center">
        <v-btn class="error" @click="dialogDelete = true"
          ><v-icon left>mdi-delete</v-icon> Hapus Data Item</v-btn
        >
      </div>

      <div class="mb-8"></div>
    </div>

    <div v-else class="d-flex justify-center">
      <span>
        Tidak ada data referensi item dari {{ produk }}, silahkan menambahkan
        data
        <v-btn x-small class="success ml-1" fab @click="dialogAdd = true"
          ><v-icon>mdi-plus-thick</v-icon></v-btn
        >
      </span>
    </div>

    <v-dialog v-model="dialogImage" width="460">
      <v-img :src="dataImage.url" contain></v-img>
      <v-card class="py-2">
        <div class="d-flex justify-center">
          <span class="mx-4">{{ dataImage.keterangan }}</span>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAdd" width="400">
      <v-card>
        <v-card-title class="text-h5 success white--text">
          Tambah Data
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="Rentang Harga Bawah"
              v-model="dataAdd.harga_bawah"
              hide-details
              :rules="requiredRules"
              required
            ></v-text-field>
            <span>{{ dataAdd.harga_bawah | formatNumber }}</span>

            <v-text-field
              label="Rentang Harga Atas"
              v-model="dataAdd.harga_atas"
              hide-details
              :rules="requiredRules"
              required
            ></v-text-field>
            <span>{{ dataAdd.harga_atas | formatNumber }}</span>

            <v-textarea
              label="Keterangan"
              v-model="dataAdd.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogAdd = false"> Batal </v-btn>
          <v-btn color="primary" text @click="addData()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEdit" width="400">
      <v-card>
        <v-card-title class="text-h5 success white--text">
          Edit Data
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="Rentang Harga Bawah"
              v-model="dataEdit.harga_bawah"
              hide-details
            >
            </v-text-field>
            <span>{{ dataEdit.harga_bawah | formatNumber }}</span>

            <v-text-field
              label="Rentang Harga Atas"
              v-model="dataEdit.harga_atas"
              hide-details
            ></v-text-field>
            <span>{{ dataEdit.harga_atas | formatNumber }}</span>

            <v-textarea
              label="Keterangan"
              v-model="dataEdit.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editData()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Data Item
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus data item
          <strong> {{ produk }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteData()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddLink" width="500">
      <v-card>
        <v-card-title class="text-h5 success white--text">
          Tambah Link Item
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="url"
              v-model="dataAddLink.url"
              :rules="requiredRules"
              required
            ></v-text-field>
            <v-textarea
              label="Keterangan"
              v-model="dataAddLink.keterangan"
              :rules="requiredRules"
              required
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogAddLink = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="addDataLink()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditLink" width="500">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Edit Link Item
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form">
            <v-text-field label="url" v-model="dataEditLink.url"></v-text-field>
            <v-textarea
              label="Keterangan"
              v-model="dataEditLink.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEditLink = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editDataLink()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteLink" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Link Item
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus link
          <strong> {{ dataDeleteLink.url }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDeleteLink = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteDataLink()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddImage" width="400">
      <v-card>
        <v-card-title class="text-h5 success white--text">
          Tambah Gambar Item
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-file-input
              label="File"
              v-model="dataAddImage.file"
              accept=".png, .jpg,"
              hide-details
              :rules="requiredRules"
              required
            ></v-file-input>
            <span class="d-flex justify-end caption"
              >Ukuran File Maksimal 30MB</span
            >
            <v-textarea
              label="Keterangan"
              v-model="dataAddImage.keterangan"
              :rules="requiredRules"
              required
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogAddImage = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="addImage()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditImage" width="400">
      <v-card>
        <v-card-title class="text-h5 success white--text">
          Edit Gambar Item
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form">
            <v-file-input
              label="File"
              v-model="dataEditImage.file"
              accept=".png, .jpg,"
              hide-details
              :rules="requiredRules"
              required
            ></v-file-input>
            <span class="d-flex justify-end caption"
              >Ukuran File Maksimal 30MB</span
            >
            <v-textarea
              label="Keterangan"
              v-model="dataEditImage.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEditImage = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editImage()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteImage" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Gambar Item
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus gambar item
          <strong> {{ dataDeleteImage.keterangan }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDeleteImage = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteImage()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idProduk: this.$route.params.idProduk,
      produk: this.$route.params.produk,
      dialogAdd: false,
      dataAdd: {
        harga_atas: "",
        harga_bawah: "",
        keterangan: "",
      },
      dialogEdit: false,
      dataEdit: {
        harga_atas: "",
        harga_bawah: "",
        keterangan: "",
      },
      dialogDelete: false,
      headers: [
        { text: "No.", value: "nomor", width: "50px" },
        { text: "Keterangan", value: "keterangan", width: "180px" },
        { text: "Link", value: "url" },
        { text: "Aksi", value: "actions", width: "88px" },
      ],
      dialogAddLink: false,
      dataAddLink: {
        url: "",
        keterangan: "",
      },
      dialogEditLink: false,
      dataEditLink: {
        id: "",
        url: "",
        keterangan: "",
      },
      dialogDeleteLink: false,
      dataDeleteLink: {
        id: "",
        url: "",
      },
      dialogAddImage: false,
      dataAddImage: {
        file: [],
        keterangan: "",
      },
      dialogImage: false,
      dataImage: {
        url: "",
        keterangan: "",
      },
      dialogEditImage: false,
      dataEditImage: {
        id: "",
        file: [],
        keterangan: "",
      },
      dialogDeleteImage: false,
      dataDeleteImage: {
        id: "",
        keterangan: "",
      },

      valid: true,
      requiredRules: [(v) => !!v || "Data harus diisi"],
      snackbar: false,
      snackbarText: "",
    };
  },
  watch: {
    dialogAdd() {
      if (!this.dialogAdd) {
        this.$refs.form.reset();
        this.dataAdd.harga_atas = "";
        this.dataAdd.harga_bawah = "";
        this.dataAdd.keterangan = "";
      }
    },
    dialogAddLink() {
      if (!this.dialogAddLink) {
        this.$refs.form.reset();
        this.dataAddLink.url = "";
        this.dataAddLink.keterangan = "";
      }
    },
    dialogAddImage() {
      if (!this.dialogAddImage) {
        this.$refs.form.reset();
        this.dataAddImage.file = [];
        this.dataAddImage.keterangan = "";
      }
    },
  },
  computed: {
    dataItemPrioritas() {
      return this.$store.getters.dataItemPrioritas;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch("getDataItemPrioritas", this.idProduk);
    },

    addData() {
      this.$refs.form.validate();
      if (this.dataAdd.harga_bawah != "" && this.dataAdd.harga_atas != "") {
        if (this.valid) {
          const data = {
            item: this.idProduk,
            harga: JSON.stringify({
              atas: this.dataAdd.harga_atas,
              bawah: this.dataAdd.harga_bawah,
            }),
            keterangan: this.dataAdd.keterangan,
          };
          this.$store.dispatch("addDataItem", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAdd = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText =
          "Rentang Harga Bawah dan Rentang Harga Atas tidak boleh kosong";
      }
    },

    showDialogEdit() {
      this.dataEdit.harga_atas = this.dataItemPrioritas.data.harga.atas;
      this.dataEdit.harga_bawah = this.dataItemPrioritas.data.harga.bawah;
      this.dataEdit.keterangan = this.dataItemPrioritas.data.keterangan;
      this.dialogEdit = true;
    },

    editData() {
      const data = {
        id: this.dataItemPrioritas.data._id,
        harga: JSON.stringify({
          atas: this.dataEdit.harga_atas,
          bawah: this.dataEdit.harga_bawah,
        }),
        keterangan: this.dataEdit.keterangan,
      };
      this.$store.dispatch("editDataItem", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEdit = false;
        }
      });
    },

    deleteData() {
      const data = {
        id: this.dataItemPrioritas.data._id,
      };
      this.$store.dispatch("deleteDataItem", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        this.dialogDelete = false;
        if (value.hasil == "success") {
          this.$router.go(-1);
        }
      });
    },

    addDataLink() {
      this.$refs.form.validate();
      if (this.dataAddLink.url != "" && this.dataAddLink.keterangan != "") {
        if (this.valid) {
          const data = {
            id: this.dataItemPrioritas.data._id,
            url: this.dataAddLink.url,
            keterangan: this.dataAddLink.keterangan,
          };
          this.$store.dispatch("addItemLink", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAddLink = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "url dan keterangan tidak boleh kosong";
      }
    },

    showDialogEditLink(item) {
      this.dataEditLink.id = item._id;
      this.dataEditLink.url = item.url;
      this.dataEditLink.keterangan = item.keterangan;
      this.dialogEditLink = true;
    },

    editDataLink() {
      const data = {
        id: this.dataEditLink.id,
        url: this.dataEditLink.url,
        keterangan: this.dataEditLink.keterangan,
      };
      this.$store.dispatch("editItemLink", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEditLink = false;
        }
      });
    },

    showDialogDeleteLink(item) {
      this.dataDeleteLink.id = item._id;
      this.dataDeleteLink.url = item.url;
      this.dialogDeleteLink = true;
    },

    deleteDataLink() {
      const data = {
        id: this.dataDeleteLink.id,
      };
      this.$store.dispatch("deleteItemLink", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogDeleteLink = false;
        }
      });
    },

    addImage() {
      this.$refs.form.validate();
      if (this.dataAddImage.file != "" && this.dataAddImage.keterangan != "") {
        if (this.valid) {
          const data = new FormData();
          data.set("id", this.dataItemPrioritas.data._id);
          data.set("file", this.dataAddImage.file);
          data.set("keterangan", this.dataAddImage.keterangan);

          this.$store.dispatch("addItemImage", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAddImage = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "File dan Keterangan tidak boleh kosong";
      }
    },

    showDialogImage(item) {
      this.dataImage.url = item.link;
      this.dataImage.keterangan = item.keterangan;
      this.dialogImage = true;
    },

    showDialogEditImage(item) {
      this.dataEditImage.id = item._id;
      this.dataEditImage.keterangan = item.keterangan;
      this.dialogEditImage = true;
    },

    editImage() {
      const data = new FormData();
      data.set("id", this.dataEditImage.id);
      data.set("file", this.dataEditImage.file);
      data.set("keterangan", this.dataEditImage.keterangan);

      this.$store.dispatch("editItemImage", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEditImage = false;
        }
      });
    },

    showDialogDeleteImage(item) {
      this.dataDeleteImage.id = item._id;
      this.dataDeleteImage.keterangan = item.keterangan;
      this.dialogDeleteImage = true;
    },

    deleteImage() {
      const data = {
        id: this.dataDeleteImage.id,
      };
      this.$store.dispatch("deleteItemImage", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogDeleteImage = false;
        }
      });
    },
  },
};
</script>